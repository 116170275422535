import React from "react"
import Layout from '../../components/layout'
import Section from '../../components/section'
import Banner from '../../components/banner'
import Container from '../../components/container'
import Typography from '../../components/typography'
import Seo from '../../components/seo';

export default function Consultancy(props) {
  return (
    <Layout {...props}>
      <Seo title="Consultancy" description="Provides precise advice and guidance to clients on different aspects of engineering. Prepare proper technical documentation upon request with regards to engineering projects." />
      <Section>
        <Banner 
          src="/img/sample-hero-image-1.jpg"
          header="Consultancy"
        />
      </Section>
      <Section>
        <Container>
          <Typography component="p">
            Provides precise advice and guidance to clients on different aspects of engineering.
            Prepare proper technical documentation upon request with regards to engineering projects.
          </Typography>
        </Container>
      </Section>
    </Layout>
  )
}
